<template>
  <div class="pb-2">
    <div v-if="this.loadpage == false" class="text-center pt-3">
      <b-spinner
        style="width: 5rem; height: 5rem;"
        variant="primary"
        label="Large Spinner"
      />
    </div>
    <b-card v-else no-body>
      <b-card-body v-if="this.errorMessage != null">
        <h1>{{this.errorMessage}}!</h1>
      </b-card-body>
      <b-card-body v-else>
        <b-table
          responsive
          :items="detailList"
          :fields="detailFields"
          table-class="table-md-responsive text-nowrap"
        >
          <template #cell(weight)="data">
            {{format_price(data.value)}}
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BForm, BFormGroup, BFormInput,
  BSpinner, BButton, BTable, BTooltip, BBadge, BPagination
} from 'bootstrap-vue'
import axios from 'axios'
import { header_token } from '@/views/token'
import { format_price } from '@/views/global_function.js'
import {jjCargoService_dotnet} from '@/views/global.js'

export default {
  name: 'JJCargoFrontendShipmentDetail',

  components: {
    BCard, BCardHeader, BCardBody, BRow, BCol, BForm, BFormGroup, BFormInput,
    BSpinner, BButton, BTable, BTooltip, BBadge, BPagination,
  },

  data() {
    return {
      shipmentID: null,
      errorMessage: null,

      detailList: [],
      detailFields: [
        { key: 'count', label: 'No', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'packingID', label: 'Packing No', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'weight', label: 'น้ำหนัก', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'cubic1', label: 'กว้าง', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'cubic2', label: 'ยาว', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'cubic3', label: 'สูง', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'area', label: 'คิว', thClass: 'text-center', tdClass: 'text-center' },
      ],

      loadpage: true
    };
  },

  async created() {
    this.loadpage = false
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    this.shipmentID = id
    await this.getShipmenDetail()
    this.loadpage = true
  },

  methods: {
    format_price,
    async getShipmenDetail() {
      let response = await axios.post(
        jjCargoService_dotnet + 'Shipment/get-shipment-detail',
        {
          "shipmentID": this.shipmentID,
          "customerID": JSON.parse(localStorage.getItem('cusId')),
        },
        { headers: header_token }
      );
      this.detailList = await response.data.shipmentDetailDataResponses
      for (let i = 0; i < this.detailList.length; i++) {
        this.detailList[i].count = i + 1
      }
      this.errorMessage = response.data.error_description
    }
  },
};
</script>

<style scoped>

</style>